import React, { useState, useEffect } from 'react';
// import { makeStyles } from 'tss-react/mui';
// import { Stepper, Step, StepLabel } from '@mui/material';
import GatewayComponent from './FormComponents/GatewayComponent';
import * as FormStyles from './FormStyles';
import { Link } from 'react-router-dom';
import * as ApplicationApi from './utils/ApplicationApi';
import Dialog from './utils/Dialog';
import CircularProgress from '@mui/material/CircularProgress';

const Form = () => {
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = useState({
    gateway: {}
  })

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  const handleOnDataChange = (newData) => {
    setData(newData);
  }

  const getSubmissionData = () => {
    let submissionData = []

    const step = 'gateway'
    const steps_keys = {
      'gateway': ['customer_name', 'customer_id', 'email_address'],
    }

    const fieldData = {}

    for (let i = 0; i < steps_keys[step].length; i++) {
      const key = steps_keys[step][i];
      // check if the value exists
      if (data?.gateway[key] === undefined || data?.gateway[key] === null) {
        continue;
      }
      fieldData[key] = data?.gateway[key];
    }
    
    submissionData = fieldData;
    
    return submissionData;
  }

  const handleFinishClick = async () => {
    setLoading(true)

    // get submission data
    const submissionData = getSubmissionData()
    const response = await ApplicationApi.Submit(submissionData)

    setLoading(false)
    setIsDialogOpen(true)
    setDialogContent(response.message)
  }

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FormStyles.Container>
        <FormStyles.FormContainer>
          <Dialog handleDialogPopUp={isDialogOpen} dialogContent={dialogContent} setIsDialogOpen={setIsDialogOpen}></Dialog>
          <FormStyles.FormHeader>
            <Link to="/">
              <FormStyles.FormLogo src={require("./images/bidfood-logo.png")} alt="Logo" />
            </Link>
          </FormStyles.FormHeader>
          <FormStyles.FormDivider />
          <div>
            <FormStyles.FormName>
              Credit Application Form
            </FormStyles.FormName>
          </div>


          <FormStyles.FormStepCard>
            {/* <FormStyles.FormStepName>General Information</FormStyles.FormStepName>
                <FormStyles.FormStepDescription>Let's begin by filling out your general information</FormStyles.FormStepDescription> */}

            {/* if current step is 1, show the GatewayComponent and stat generalcounter also do not make it count from 0 again*/}

            {loading && 
              (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
                  <CircularProgress/>
                </div>
              )
            }

            {<GatewayComponent handleOnDataChange={handleOnDataChange}/>}
          </FormStyles.FormStepCard>

          <FormStyles.FormFooter>
            {<FormStyles.FormButton onClick={handleFinishClick}>Submit</FormStyles.FormButton>}
          </FormStyles.FormFooter>
        </FormStyles.FormContainer>
      </FormStyles.Container>
    </>
  );
};

export default Form;