import React, { createContext, useState } from 'react';

export const GatewayContext = createContext();

export const GatewayProvider = ({ children }) => {
  const [data, setData] = useState({
    gateway: {}
  });

  // handle fields change in all form pages
  const updateData = (key, value) => {    

    const newData = {
      ...data,
      [key]: {
        ...data[key],
        ...value,
      },
    }

    setData(newData);
  };

  const props = {
    data,
    updateData
  }

  return (
    <GatewayContext.Provider value={props}>
      {children}
    </GatewayContext.Provider>
  );
}

export default GatewayContext;