import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Link,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FormStepName, FormStepDescription, CustomInputLabel, HelperImage } from './FormComponentsStyles';

import { GatewayContext } from '../Contexts/GatewayContext';

const GatewayComponent = ({ handleOnDataChange }) => {
  const { data, updateData } = React.useContext(GatewayContext);

  // state for the whole form
  const [formState, setFormState] = useState({
    customer_name: data.gateway.customer_name || '',
    customer_id: data.gateway.customer_id || '',
    email_address: data.gateway.email_address || '',
  });

  // function to update form data and call callback
  const handleFormDataChange = (field, value) => { 
    const updatedFormState = { ...formState, [field]: value };
    // setFormState(updatedFormState);
    updateData('gateway', updatedFormState);
    setFormState(updatedFormState);
    handleOnDataChange({ ...data, gateway: updatedFormState });
    console.log(updatedFormState);
  };

  return (
    <section className='gateway_sec'>
      <FormStepName>Customer Information</FormStepName>
      <FormStepDescription>Please fill all the required fields</FormStepDescription>
      <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="customer_name"
            onChange={(e) => handleFormDataChange('customer_name', e.target.value)}
            value={formState.customer_name}
            placeholder="MBF Test Account"
          />
          <CustomInputLabel htmlFor="customer_name" className='input_label'>
            Customer Name
          </CustomInputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="customer_id"
            onChange={(e) => handleFormDataChange('customer_id', e.target.value)}
            value={formState.customer_id}
            placeholder="9284"
          />
          <CustomInputLabel htmlFor="customer_id">
            Customer ID
          </CustomInputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
        <TextField
            fullWidth
            id="email_address"
            onChange={(e) => handleFormDataChange('email_address', e.target.value)}
            value={formState.email_address}
            placeholder="john@gmail.com"
          />
          <CustomInputLabel htmlFor="email_address">
            Email Address
          </CustomInputLabel>
        </Grid>
        <Grid item xs={12} sm={0}>
          <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
              <Typography>Need help finding your information ?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/">
                <HelperImage src={require("./../images/infohelper.jpg")} alt="Find your info" />
              </Link>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid >
    </section>
  );
};

export default GatewayComponent;