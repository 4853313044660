import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import GatewayForm from './Forms/Gateway/Form';
import { GatewayProvider } from './Forms/Gateway/Contexts/GatewayContext';


function App() {
  // create router for each component
  const GatewayFormPage = () => {
    return (
      <GatewayProvider>
        <GatewayForm />
      </GatewayProvider>
    )
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<GatewayFormPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;