import config from "../../../config"

const base_url = config.env === "Production" ? config.api.prod_url : config.api.dev_url

export async function request(api, method, headers, body = []) {
    const url = base_url + api;

    const request_action = await fetch(url, {
        method: method,
        body: method === "GET" ? null : JSON.stringify(body),
        headers: headers
    });

    return request_action;
}

export async function Submit(body){
    const api = "Gateway/SubmitForm"
    const method = "POST";

    const headers = {
        "Content-Type": "application/json"
    }

    const response = await request(api, method, headers, body);
    const data = await response.json();

    return data;
}